<script>
export default {
  name: "LogosMistralAiIcon",
};
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.1em"
    height="1em"
    viewBox="0 0 256 233"
  ><path d="M186.182 0h46.545v46.545h-46.545z" /><path
    fill="#F7D046"
    d="M209.455 0H256v46.545h-46.545z"
  /><path d="M0 0h46.545v46.545H0zm0 46.545h46.545V93.09H0zm0 46.546h46.545v46.545H0zm0 46.545h46.545v46.545H0zm0 46.546h46.545v46.545H0z" /><path
    fill="#F7D046"
    d="M23.273 0h46.545v46.545H23.273z"
  /><path
    fill="#F2A73B"
    d="M209.455 46.545H256V93.09h-46.545zm-186.182 0h46.545V93.09H23.273z"
  /><path d="M139.636 46.545h46.545V93.09h-46.545z" /><path
    fill="#F2A73B"
    d="M162.909 46.545h46.545V93.09h-46.545zm-93.091 0h46.545V93.09H69.818z"
  /><path
    fill="#EE792F"
    d="M116.364 93.091h46.545v46.545h-46.545zm46.545 0h46.545v46.545h-46.545zm-93.091 0h46.545v46.545H69.818z"
  /><path d="M93.091 139.636h46.545v46.545H93.091z" /><path
    fill="#EB5829"
    d="M116.364 139.636h46.545v46.545h-46.545z"
  /><path
    fill="#EE792F"
    d="M209.455 93.091H256v46.545h-46.545zm-186.182 0h46.545v46.545H23.273z"
  /><path d="M186.182 139.636h46.545v46.545h-46.545z" /><path
    fill="#EB5829"
    d="M209.455 139.636H256v46.545h-46.545z"
  /><path d="M186.182 186.182h46.545v46.545h-46.545z" /><path
    fill="#EB5829"
    d="M23.273 139.636h46.545v46.545H23.273z"
  /><path
    fill="#EA3326"
    d="M209.455 186.182H256v46.545h-46.545zm-186.182 0h46.545v46.545H23.273z"
  /></svg>
</template>
